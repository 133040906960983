@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mika";
  src: url("./assets/fonts/MIKA-MELVAS-RIONASANS-REGULAR.woff2")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nothing";
  src: url("./assets/fonts/nothing-font-5x7.woff2") format("truetype");
  font-display: swap;
}

.font-mika {
  font-family: "Mika", sans-serif;
}

.font-nothing {
  font-family: "Nothing", sans-serif;
}

html {
  height: 100vh;
  overflow-y: auto;
}

body {
  background-image: url("./assets/images/Bg-compressed-compressed.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  background-color: #eb3b1a;
  font-family: "Mika", sans-serif;
}

.special-title {
  font-family: "Nothing", sans-serif;
}
